import React from "react"

const Excerpt = () => (
  <div className="excerpt">
    <h1 className="excerpt__header">Read an Excerpt</h1>
    <div className="excerpt__chapter-header">
      <h2 className="excerpt__chapter-name">Day 33: SECOND CHANCES</h2>
      <p className="excerpt__chapter-epigraph">
        The real fault is to have faults and not try to mend them. Confucius
      </p>
    </div>
    <div className="excerpt__text">
      <p className="excerpt__text--normal">
        The U.S. Holocaust Memorial Museum is home to a very significant
        commemorative dedication. It serves in memory of the 6,000,000 people
        who were put to death in gas chambers or died in concentration camps
        during Hitler’s Nazi regime (1933-1945).
      </p>
      <p className="excerpt__text--normal">
        The Nazis came to power in 1933. Germany and its people had no idea what
        result the decimating outcome of the regime would bring. They endorsed
        policies without thinking.
      </p>
      <p className="excerpt__text--normal">
        When WWII broke out, times were extremely difficult. Germany collapsed
        in 1945 after it lost the war. With destruction great and malnutrition
        vast, a slow rebuilding process ensued.
      </p>
      <p className="excerpt__text--normal">
        During WWII, my grandfather fought in the German army against the
        Soviets at the Russian front. He had a scrape with the Nazi regime while
        making machine parts for the war effort in a large factory. One day
        before eating his lunch, he created a little jingle prayer in jest. In
        the company of his co-workers, he remarked:
      </p>
      <ul className="excerpt__text--poem">
        <li> Come Mr. Göbbels, be our guest</li>
        <li>and give us what you promised us. </li>
        <li> We don’t want margarine or herring,</li>
        <li> we want what you eat and Mr. Göring.</li>
      </ul>
      <p className="excerpt__text--normal--mobile">
        Dr. Joseph Göbbels was Hitler’s Minister of Propaganda and
        Enlightenment. Through manipulation and distortion, he promised the
        German people victory. He promised them the good life. Of course, none
        of it ever materialized.
      </p>
      <p className="excerpt__text--normal--mobile">
        Hermann Göring was the leader of the National Socialist Party in
        Hitler’s government. Obviously, they both ate well and enjoyed a life of
        extravagance. Living in a dictatorship, no one was allowed to comment
        about Hitler and definitely not create political jokes.
      </p>
      <p className="excerpt__text--normal--mobile">
        The next day after the incident, my grandfather was interrogated and his
        co-workers were questioned about his comments. A man subsequently showed
        up to work side-by-side with him under the auspices of being a new
        recruit. He was dressed in a brand new uniform. Everyone knew the man
        was a member of the Gestapo as no one could afford a new work uniform
        during such hard times. The man continuously made negative comments
        about Hitler in an effort to make my grandfather acquiesce, which he did
        not do. Had he made a negative statement, he would have been led off to
        a concentration camp.
      </p>
      <p className="excerpt__text--normal--mobile">
        Joseph Göbbels committed suicide when the Soviet troops stormed Berlin
        on May 1, 1945. Hermann Göring surrendered to American forces in 1945.
        He was charged at the Nuremberg Trials. After a verdict of guilty and
        being sentenced to death, Göring escaped a fate of hanging by poisoning
        himself.
      </p>
      <p className="excerpt__text--normal--mobile">
        I wonder if any of the individuals responsible for war crimes repented?
        Even now, we see the remembrances of how cruel humans can be to one
        another. Only when we recognize ourselves as sinners and honestly return
        to purifying and recharging ourselves are we able to be enlightened and
        helped by God. We can always have another chance. That’s reality. Albert
        Einstein once said, “One cannot but be in awe when contemplating the
        mysteries of eternity, of life, of the marvelous structure of reality.”
        When we turn away from the teachings of Jesus, His arms are still open.
        God is a God of “Second Chances.”
      </p>
      <p className="excerpt__text--normal--mobile">
        <ul className="excerpt__text--poem">
          <li>Red-stained the clean white robe </li>
          <li>He wore, as blood did spill from every pore. </li>
          <li> Then with His arms still limp and weak,</li>
          <li>did lift me up and kiss my cheek.</li>
          <li>Through your repentance did He say,</li>
          <li>your sins have now been washed away.</li>
        </ul>
      </p>
    </div>
  </div>
)

export default Excerpt
