import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Excerpt from "../components/excerpt"
import BookOrder from "../components/book-order"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <Excerpt />
    <BookOrder />
  </Layout>
)

export default SecondPage
